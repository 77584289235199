.dashboard_sidebar_color{
    background: linear-gradient(270deg, #4D4D4D -13.94%, #4D4D4D 37.77%, #4A4A4A 68.41%, #434343 83.19%, #363636 94.57%, #232323 104.21%, #0C0C0C 112.66%, #000000 116.09%);
}
.dashboard_options_text{
    padding-left: 5px;
    color:#E6E6E6;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.1em;
    display:inline-block;
}
.dashboard_options_text_selected{
    color:black;
    padding-left: 5px;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.1em;
    display:inline-block;
}
.logo_style{
    color: white;
    font-size: 1.2rem;
    padding-right: 5px;
    display:inline-block;
}
.logo_style_selected{
    color: black;
    font-size: 1.2rem;
    padding-right: 5px;
    display:inline-block;
}

.selected{
    cursor: pointer;
    background-color:#25BDBD;
    color:black;
    padding-left: 10px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.unselected{
    cursor: pointer;
    padding-left: 10px;
    padding-top:0.5rem;
    padding-bottom:0.5rem;
}
.item_background_color{
    --ion-item-background:#E6E6E6;
}

.homeMainFooter {
    font-family: PoppinsRegular;
    font-size: 0.8rem;
    margin-left: 1.75rem;
    margin-top: 1%;
}

.update-pwd-toast {
    justify-content: center;
    display: flex;
    width: 100%;
    align-items: center;
    font-family: RobotoRegular;
    font-size: 1.1rem;
    color: #58585A;
}

.toast-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .toast {
    width: 60%;
    align-items: center;
    display: inline-block;
    padding: 1rem;
    margin-top: 2%;
    font-family: RobotoRegular;
    font-size: 2rem;
    color: #58585A;
    margin: auto;
  }

.toast-success {
    background: #34E40021;
}

.toast-error {
    background: #FF09098A;
}

.toast-partial {
    background: #F1EA2547;
}

.register-toast {
    width: auto;
}


@media screen and (min-height: 800px) {
    .selected{
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        padding-left: 5px;
    }
    .unselected{
        padding-top:1.5rem;
        padding-bottom:1.5rem;
        padding-left: 5px;
    }
    .logo_style{
        color: white;
        font-size: 2.188rem;
        padding-right: 20px;
        padding-left: 20px;
        display:inline-block;
    }
    .logo_style_selected{
        color: black;
        font-size: 2.188rem;
        padding-right: 20px;
        padding-left: 20px;
        display:inline-block;
    }

    .toast-container {
        width: 70%;
        margin: auto;
    }
    
 }