.filter-container {
    height: 70%;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    font-family: PoppinsRegular;
}

.filter-box {
    width: 80%;
    display: flex;
    margin: 1rem;
    justify-content: space-between;
}

.filter {
    border-radius: 0.5rem;
    --background: #F6F6F6;
    width: 80%;
}

.show-results {
    width: 15%;
}


.chip-box {
    width: 80%;
    display: flex;
    margin: 1rem;
}

.filter-icon {
    width: 4rem;
    height: 3rem;
    background-color: #1CBDBE;
    color: white;
    border-radius: 0.5rem;
    margin-left: 1rem;
}

.filter-button {
    --background: #1CBDBE;
    text-transform: none;
    font-size: 1rem;
    font-family: PoppinsRegular;
    height: 3rem;
    margin: 0;
}

.drop-down {
    width: 30%;
    border-radius: 0.5rem;
    --background: #F6F6F6;
}

.popover-item {
    font-family: PoppinsRegular;
    font-size: 0.8rem;
}

.toggle-option {
    --background-checked: #1CBDBE;
    --handle-background-checked: white;
    --handle-spacing: 2px;
    width: 5rem;
    height: 1.6rem;
}

.toggle-item {
    --background: #F6F6F6;
    font-family: PoppinsRegular;
    width: 45%;
    padding: 0;
    border-radius: 0.5rem;
}

.option-align {
    text-align: center;
}

.value {
    border-radius: 0.5rem;
    --background: #F6F6F6;
}

.select {
    width: 100%;
}

@media (max-width: 750px) {
    .filter-container {
        margin-top: 15%;
    }

    .filter-box {
        margin-bottom: 1rem;
    }

    .filter-button {
        width: 30%;
    }

    .filter {
        width: 68%;
    }

    .toggle-option {
        width: 2.5rem;
        height: 1.4rem;
    }

    .toggle-item {
        width: 48%;
        font-size: 0.75rem;
    }

    .filter-mob {
        flex-direction: column;
        align-items: center;
    }

    .mob-view {
        width: 100%;
        margin-bottom: 1rem;
    }
}