
ion-button {
    --background: #22BCBD;
  
  }
  .datasync_cards_container{
    display: flex;
    padding-bottom: 25px; 
  }
  .datasync_patientcard_container{
    width: 356px;
    max-height: 145px;
    border: 1px solid #999999;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    padding-top: 10px; 
}

.datasync_log_container{
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  border-bottom: 1px solid #B3B3B3;
  cursor: pointer;
  padding-bottom:20px;
}
.datasync_log_container_selected{
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  border-bottom: 1px solid #B3B3B3;
  background-color: #CCCCCC;
  cursor: pointer;
  padding-bottom:20px;
}
.datasync_sync_id{
   font-size: 14px;
   color: #535352;
   padding-bottom: 5px;
}
.datasync_day{
  font-size: 65px;
  color: #22BCBD;
  letter-spacing: 0.2em;
  text-align: center;
}
.datasync_month{
  font-size: 29px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #22BCBD
}
.datasync_period_container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.datasync_syncperiod_text{
  font-size: 14px;
  color: #535352;
  padding-bottom: 10px;
}
.datasync_syncperiod_data{
  font-size: 18px;
  color: #22BCBD;
  letter-spacing: 0.2em;
  padding-bottom: 10px;
}
.datasync_ionbutton_style{
  background-color: #22BCBD;
   margin-bottom: 20px;
    letter-spacing: 0.2em;
}
.datasync_patientcard_container{
  display: flex;
  justify-content: space-around;
  padding-top: 10px;
  margin-left: 30px;
}
.datasync_patientcard_header{
  font-size: 14px;
  text-align: center;
  color: #535352;
  font-weight: 400;
  padding-bottom: 25px;
}