.formfield_spacing{
  padding-top: 15px;
  padding-bottom: 15px;
}
@media screen and (max-height: 800px) {
    .formfield_spacing{
        padding-top: 10px;
        padding-bottom: 10px;
      }
 }
