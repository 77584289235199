.widget-container {
    text-align: center;
    height: 65%;
}

.widget-container-mob {
    display: none;
}

.no-widgets {
    display: none;
}

.category-title {
    font-size: 1.5rem;
    font-family: PoppinsMedium;
}

.col {
    margin-top: 0.5%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.icon {
    width: 20%;
    position: absolute;
}

.cover {
    width: 90%;
    box-shadow: 0px 24px 91px #030B6114;
    cursor: pointer;
}

.coverLast {
    width: 60%;
    margin: auto;
    box-shadow: 0px 24px 91px #030B6114;
    cursor: pointer;
}

.widget-label {
    position: absolute;
    width: 100%;
    text-align: center;
}

.ass-dev {
    margin-left: 39%;
}

.rev {
    margin-left: 6%;
}

.forbidden-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65%;
}

.forbidden {
    width: 55%;
    height: 30%;
    align-items: center;
    display: flex;
    padding: 1rem;
    margin-top: 2%;
    font-family: RobotoBold;
    font-size: 2rem;
    color: #58585A;
    justify-content: center;
    background: #FF09098A;
}

@media (max-width: 750px) {
    .widget-container {
        display: none;
    }

    .widget-container-mob {
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: center;
    }

    .row {
        margin-top: 8%;
    }

    .cover {
        width: 98%;
        display: flex;
        justify-content: center;
        background: white;
    }

    .coverLast {
        width: 42%;
        display: flex;
        justify-content: center;
        background: white;
    }

    .icon {
        width: 30%;
        top: 10%;
    }

    .widget-label {
        left: auto;
        bottom: 10%;
        font-size: 0.75rem;
        margin-left: auto !important;
    }

    .title {
        font-size: 1rem;
        margin-top: 15%;
        font-family: PoppinsMedium;
    }
}