.patientlog_container{
    border-top: 2px solid #999999;
    height:75vh;
    overflow-y:auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    
}
.patientlog_card_container{
    width: 85%;
    height: 348px;
    border: 1px solid #999999;
    padding: 20px;
    margin-top: 30px;
    border-radius: 5px;
}
.patientlog_carddata_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:fit-content;
}
.patientlog_details_container{
    border: 1px solid #999999;
    height: 182px ;
    margin-top: 5px;
    padding: 10px;
}
.patientlog_text_styles{
    font-size: 12px;
     margin-top: 33px;
      color: #535352;
}
.patientdata_symptom_logo{
    font-size: 14px;
    color: #535352; 
}
.patientdata_day{
    font-size: 3.25rem;
    letter-spacing: 0.2em;
    color: #22BCBD;
}
.patientdata_month{
    font-size: 1.5rem;
    letter-spacing: 0.2em;
    color: #22BCBD;
}
.patientdata_detail{
    width: 284px;
    height: 46px;
    border: 1px solid #999999;
    border-radius: 5px;
}
.patientlog_comments_container{
    height: 111px;
    border: 1px solid #999999;
    width: 100%;
}
@media screen and (max-height: 800px) {
    .patientlog_card_container{
        height: 268px;
    }
    .patientlog_details_container{
        height: 120px ;
    }
    .patientdata_day{
        font-size: 3rem;
    }
    .patientdata_month{
        font-size: 1.3rem;
    }
    .patientlog_comments_container{
        height: 110px;
        border: 1px solid #999999;
        width: 100%;
    }
 }