.landing-page {
    position: relative;
    height: 100vh;
    overflow-y: hidden;
}

.landing-page-header {
    height: 30%;
}

.landing-page-main {
    text-align: left;
    display: flex;
    flex-flow: column;
}

.landing-page-footer {
    height: 2vh;
    min-height: 20px;
    max-height: 30px;
    text-align: center;
    font-family: PoppinsRegular;
    font-size: 0.8rem;
    color: grey;
}

.homeMain {
    height: 70%;
}

.homeMainFooter {
    font-family: PoppinsRegular;
    font-size: 0.8rem;
    margin-left: 1.75rem;
    margin-top: 1%;
}

.homeMainFooter-invisible {
    display: none;
}

.loginButton {
    --background: #1CBDBE;
    width: 25%;
    height: 40px;
    text-transform: none;
    font-size: 1rem;
    font-family: PoppinsRegular;
    margin: 1px;
    padding: 5px;
}

.dna-img {
    object-fit: fill;
    width: 100%;
    height: 100%;
}

.category-button {
    width: 50%;
    height: 227px;
    box-shadow: 0px 24px 91px #030B6114;
    --background: white;
}

.card-content-md h1 {
    color: #030B61;
    font-size: 1rem;
    font-family: PoppinsSemiBold;
    cursor: pointer;
}

.img-heading {
    position: absolute;
    color: white;
    font-family: PoppinsRegular;
    font-size: 1.5rem;
    width: 50%;
    text-align: center;
}

.logo-home {
    position: absolute;
    top: 5%;
    left: 5%;
    width: 102px;
    z-index: 1;
}

.img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
}

.update-pwd-toast {
    justify-content: center;
    display: flex;
    width: 100%;
    align-items: center;
    font-family: RobotoRegular;
    font-size: 1.1rem;
    color: #58585A;
}

.router-page {
    position: initial;
}

.toast-container {
    height: 10%;
    width: 50%;
    margin-left: 2rem;
}

.toast {
    width: 60%;
    align-items: center;
    display: flex;
    padding: 1rem;
    margin-top: 2%;
    font-family: RobotoRegular;
    font-size: 1rem;
    color: #58585A;
}

.toast-success {
    background: #34E40021;
}

.toast-error {
    background: #FF09098A;
}

.toast-partial {
    background: #F1EA2547;
}

.register-toast {
    width: auto;
}

@media (max-width: 750px) {

    .landing-page-header {
        display: none;
    }

    .landing-page-main {
        height: 98vh;
    }

    .homeMainFooter {
        text-align: center;
        font-family: PoppinsRegular;
        bottom: 1px;
        font-size: 0.8rem;
        margin: 0;
    }


    .img-container, .logo-home {
        display: none;
    }

    .menu.sc-ion-buttons-md-h {
        color: black;
    }

    .card-content-md h1 {
        font-size: 0.75rem;
    }

    .homeMain {
        height: 100%;
    }

    .toast-container {
        width: 70%;
        margin: auto;
    }
}
