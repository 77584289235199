@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    margin: 0px;
    padding: 0px;
}

input[type='radio'] {
    box-sizing: border-box;
    appearance: none;
    background: transparent;
    outline: 1px solid #999999;
    border: 1px solid white;
    width: 32px;
    height: 32px;
    border-radius: 5px;
  }
  
  input[type='radio']:checked {
    background: #22BCBD;
  }

  input[type='checkbox'] {
    box-sizing: border-box;
    appearance: none;
    background: transparent;
    outline: 1px solid #999999;
    border: 1px solid white;
    width: 32px;
    height: 32px;
    border-radius: 5px;
  }
  
  input[type='checkbox']:checked {
    background: #22BCBD;
  }

  .date{
    width: 220px;
    height: 35px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid #999999;
    color: #535352;
  }
  .date_invalid{
    width: 220px;
    height: 35px;
    border-radius: 5px;
    background-color: transparent;
    border: 2px solid #f49b9b;
    color: #535352;
  }  
  .text{
    width: 520px;
    height: 35px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid #999999;
    color: #535352;
  }
  .textarea{
    width: 520px;
    height: 70px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid #999999;
    color: #535352;
  }  
  .number{
    width:85%;
    height: 35px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid #999999;
    color: #535352;
  }
  .number_invalid{
    width: 85%;
    height: 35px;
    border-radius: 5px;
    background-color: transparent;
    border: 2px solid #f49b9b;
    color: #535352;
  }
  .si-unit {
    font-size: 14px;
   
  }
  .number_data_container {
    width: 220px;

  }
  .select{
    width: 220px;
    height: 35px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid #999999;
    color: #535352;
  }
  .time{
    width: 35%;
    height: 35px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid #999999;
    color: #535352;
  }
  .tz {
    width: 60%;    
    height: 35px;    
    font-size: 14px;
    background-color: transparent;
    border: 1px solid #999999;
    color: #535352;    
   
  }  
  .time_data_container {
    width: 220px;

  }
  .select{
    width: 250px;
    height: 35px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid #999999;
    color: #535352;
  }


input::file-selector-button {
  width: 250px;
  height: 35px;
  background-color: transparent;
    font-weight: bold;
    color:  #535352;
    padding: 0.5em;
    border: thin solid grey;
    border-radius: 5px;
}
button {
  all: unset;
  cursor: pointer;
}
.ecrf_height{
  height: 55vh;
}
@media screen and (max-height: 800px) {
 .ecrf_height{
  height: 50vh;
 }
}

