.devices-row {
    font-family: PoppinsRegular;
}

.radio-button {
    text-align: center;
}

.table-cell {
    border-color: #F6F6F6;
    border-width: .01em;
    border-style:solid; margin-bottom : -1px;
}