.search-box {
    display: flex;
    margin: 1rem;
    justify-content: left;
    font-family: PoppinsRegular;
}

.search-input {
    border-radius: 0.5rem;
    --background: #F6F6F6;
    width: 50%;
}

.filter-icon {
    width: 4rem;
    height: 3rem;
    background-color: #1CBDBE;
    color: white;
    border-radius: 0.5rem;
    margin-left: 1rem;
}

.search-icon {
    font-size: 32px;
    width: 4rem;
    height: 3rem;
    background-color: #1CBDBE;
    color: white;
    border-radius: 0.5rem;
    margin-left: 1rem;
}

.info-icon {
    cursor: pointer;
    font-size: 1.5rem; /* Adjust size as needed */
    color: #007bff; /* Change to your preferred color */
  }
  
  .tooltip {
    bottom: 125%; /* Position above the icon */
    background-color: #333; /* Tooltip background color */
    color: #fff; /* Tooltip text color */
    padding: 5px;
    border-radius: 5px;
    white-space: nowrap;
    z-index: 10;
    opacity: 0.8;
    font-size: 0.875rem; /* Tooltip font size */
    margin-bottom: 5px; /* Space between icon and tooltip */
  }

.container-grid {
    margin-left: 2rem;
    word-break: break-all;
}

.row-header {
    font-family: PoppinsMedium;
}

.table-heading {
    font-family: PoppinsSemiBold;
    margin-left: 2rem;
    font-size: 1.25rem;
    color: #1CBDBE;
}

.assign-button {
    --background: #1CBDBE;
    width: 15%;
    margin-top: 0.5%;
    text-transform: none;
    font-size: 1rem;
    font-family: PoppinsRegular;
    margin-left: 2rem;
}

.spinner-box {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 1rem;
    justify-content: center;
    align-items: center;
    font-family: PoppinsRegular;
}

.ion-spinner {
    color: #1CBDBE;
    transform: scale(3);
    margin-left: 4rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.row-box {
    border-radius: 0.5rem;
    --background: #F6F6F6;
    font-family: PoppinsRegular;
}

.toast {
    width: 60%;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 1rem;
    margin-top: 1%;
    margin-left: 2rem;
    font-family: RobotoRegular;
    font-size: 1rem;
    color: #58585A;
}

.toast-success {
    background: #34E40021;
}

.toast-info {
    background: #F1EA2547;
}

.toast-error {
    background: #FF09098A;
}

.table-header-cell {
    border-color: #1CBDBE;
    border-width: .01em;
    border-style:solid;
    border-radius: 3px;
    background-color: #F6F6F6;
}

@media (max-width: 750px) {

    .assign-button {
        --background: #1CBDBE;
        width: 12rem;
        margin-top: 2%;
        text-transform: none;
        font-size: 1rem;
        font-family: PoppinsRegular;
        margin-left: 2rem;
    }

    .search-box-mob {
        margin-top: 15%;
    }

}
