.color {
  color: red;
}

.img {
  height: 100%;
  width: 100%;
}

.grid {
  padding: 0;
}

.column {
  padding: 0;
}

@media (max-width: 750px) {
  .container {
    display: none !important;
  }
}
