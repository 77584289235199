  @font-face {
    font-family: 'PoppinsRegular';
    src: url('fonts/Poppins/ttf/Poppins-Regular.ttf');
  }
  @font-face {
    font-family: 'PoppinsMedium';
    src: url('fonts/Poppins/ttf/Poppins-Medium.ttf');
  }
  @font-face {
    font-family: 'PoppinsSemiBold';
    src: url('fonts/Poppins/ttf/Poppins-SemiBold.ttf');
  }
  @font-face {
    font-family: 'RobotoRegular';
    src: url('fonts/Roboto/ttf/Roboto-Regular.ttf');
  }
  @font-face {
    font-family: 'RobotoMedium';
    src: url('fonts/Roboto/ttf/Roboto-Medium.ttf');
  }
  @font-face {
    font-family: 'RobotoBold';
    src: url('fonts/Roboto/ttf/Roboto-Bold.ttf');
  }

