.pagination-controls {
    display: flex;
    margin-bottom: 1rem;
}

.pagination-controls__button {
    background-color: white;
    border-radius: 0.15em;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    cursor: default;
    margin: 0 0.25em;
    padding: 0.5em 0.75em;
}

.pagination-controls__button:first-child {
    margin-left: 0;
}

.pagination-controls__button:last-child {
    margin-right: 0;
}

.pagination-controls__button:hover:not(:active) {
    transition: transform 0.15s;
    transform: translateY(-1px);
}

.pagination-controls__button--active {
    background-color: #1CBDBE;
    color: white;
}

.pagination-controls__button--active:hover,
.pagination-controls__button--active:hover:not(:active) {
    transform: none;
}
