.device-content {
    height: 55%;
    overflow: auto;
    padding: 0 1rem;
}

.device-item {
    margin: 0.5rem;
    border-radius: 0.5rem;
    --background: #F6F6F6;
    font-family: PoppinsRegular;
}

.device-date-value {
    width: 80%;
    padding: 0.5rem;
}

.picker-wrapper.sc-ion-picker-md {
    font-family: PoppinsRegular;
    top: 10%;
    background: white;
    border-style: solid;
    --border-radius: 5px;
    --border-style: solid;
    --border-color: #1CBDBE;
    --border-width: 3px;
}

.device-title {
    font-family: RobotoBold;
    font-size: 1.5rem;
    color: #1CBDBE;
    margin: 1rem 0 0 1rem;
}

.device-label {
    font-family: PoppinsMedium;
    font-size: 1.25rem;
}

.device-toggle {
    --background-checked: #1CBDBE;
    --handle-background-checked: white;
    --handle-spacing: 2px;
    width: 4rem;
    height: 1.6rem;
}

.calendar-icon {
    width: 2.5rem;
    height: 2.5rem;
}

.addcircle-icon {
    width: 2.5rem;
    height: 2.5rem;
    color: #1CBDBE;
}

body, html {
    height: 100%;
    margin: 0;
    padding: 0
}

/* margin and padding 0 for firefox*/


.device-form {
    width: 60%;
}

.admin_dash_container{
    background:#E6E6E6;
    height: 100vh;
    overflow-y: auto;
}

@media (max-width: 750px) {
    .device-content {
        height: 67%;
        margin-top: 3%;
    }

    .device-form {
        width: 100%;
    }
}
