
/*item md in-list ion-activatable ion-focusable hydrated item-label
list item md list-md hydrated

#homeMenu div.menu-inner

.menu #homeMenu {
    background: yellow;
}
*/


.hamburger { 
    --background:#1CBDBE;
    background:#1CBDBE;    
    font-family: PoppinsSemiBold;
    font-size: 0.938;
    text-align: end;
}

.ham-item, .ham-chip, .ham-list, .ham-list .ham-item, .hamburger .ham-item {
    background:#1CBDBE;
    --background:#1CBDBE;
    --color: white;
    flex-flow: unset;
    flex: 1 1 0%;
    flex-direction: inherit;
    margin: 5%;
    justify-content: flex-end;
}


.hamburger > .ham-top-item, .ham-close{
    background: #1CBDBE;
    --background: #1CBDBE;
    margin-top: 3%;
    font-size: 2.5rem;
    position: relative;
    color: white;
}

.ham-item-icon{
    margin-left: auto;
    color: white;
    padding-right: 10px;
}

/*
.ham-item-label{
color: white;
}*/

.menu {
    position: absolute;
    top: 5%;
    right: 7%;
    color: white;
    font-size: 0.938rem;
    font-family: PoppinsMedium;
}

.whiteunderline {
    top: -8px;
    width: 12%;
    left: 3%;
    border-radius: 0.3rem;
    border-bottom: 0.2rem solid white;
}

.menu-text{
    text-transform: capitalize;
    font-family: PoppinsSemiBold;
    font-size: 0.938;
    color: white;
}
