.selectedTab{
    background: linear-gradient(270deg, #4D4D4D -13.94%, #4D4D4D 37.77%, #4A4A4A 68.41%, #434343 83.19%, #363636 94.57%, #232323 104.21%, #0C0C0C 112.66%, #000000 116.09%);
    padding: 15px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    color: white !important
}
.unselectedTab{
    text-align: center;
    background: transparent;
    padding: 15px;
    font-size: 14px;
    font-weight: 400;
    cursor:pointer;
    color:black;
}
.ecrf_patientname{
    border: 1px solid #999999;
    width: 342px;
    height: 35px;
    border-radius: 5px;
}
.ecrf_date{
    width: 220px;
    height: 35px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid #999999;
    color: #535352;
}
.ecrf_add_patient{
    width: 220px;
    text-align: center;
    height: 35px;
    background-color: #4D4D4D;
    color: white;
    font-size: 13px;
    letter-spacing: 0.2em;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.ecrf_add_patient{
    width: 220px;
    text-align: "center";
    height: 35px;
    background-color: #4D4D4D;
    color: white;
    font-size: 13px;
    letter-spacing: 0.2em;
    border-radius: 5px;
    margin-top: 20p;
    margin-bottom: 20px; 
}
.ecrf_columns_spacing{
    margin-right: 45px;
    margin-bottom: 40px;
}

.deleteBtnColor {
    --background: #9D9D9C;
}

.saveBtnColor {
    --background: #4D4D4D;
}

.selectedBtnColor {
    --background:  #4D4D4D;
}

.selectOption {
    background-color: #E6E6E6;
    padding: 15px;
    font-size: 14px;
    font-weight: 400;
    cursor:pointer;
    color:black;
}

.unselectOption{
    background: transparent;
    padding: 15px;
    font-size: 14px;
    font-weight: 400;
    cursor:pointer;
    color:black;
    border-width: 0;
}

.selectedOption{
    background: linear-gradient(270deg, #4D4D4D -13.94%, #4D4D4D 37.77%, #4A4A4A 68.41%, #434343 83.19%, #363636 94.57%, #232323 104.21%, #0C0C0C 112.66%, #000000 116.09%);
    padding: 15px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    color: white !important;
    border-width: 0;
}

.patientinfo_height{
    height: 75vh;
    overflow: scroll;
}



input.text {
    padding-left: 2px;
}

.patientinfo_selectedEntry{
    font-size: 16px;
    color: #22BCBD;
    padding-bottom: 5px;
 }

 .ecrf-toast-success {
    background: #4D4D4D;
    color:#FFFFFF
}

.ecrf-toast-error {
    background: #f49b9b;
    color:#FFFFFF
}

.ecrf-invalid-highlight {
    outline: 1px solid red;
}

@media screen and (max-height: 800px) {
    .selectedTab{
        padding: 3px 8px;
    }
    .unselectedTab{ 
        padding: 3px 8px;
    }
    .ecrf_patientname{
        border: 1px solid #999999;
        width: 180px;
        height: 30px;
    }
    .ecrf_sitename{
        border: 1px solid #999999;
        width: 180px;
        height: 30px;
    }    
    .ecrf_date{
        width: 120px;
        height: 30px;
        border-radius: 5px;
    }
    .ecrf_add_patient{
        height: 30px;
        margin-top:5px;
        margin-bottom: 10px; 
    }
    .ecrf_columns_spacing{
        margin-right: 45px;
        margin-bottom: 20px;
    }
 }
