.loginButton {
    --background: #1CBDBE;
    width: 25%;
    margin-top: 2%;
    text-transform: none;
    font-size: 1rem;
    font-family: PoppinsRegular;
}
.login-title {
    font-family: PoppinsMedium;
    font-size: 2rem;
}
.content {
    font-family: PoppinsRegular;
    margin-top: 1%;
    font-size: 1.25rem;
    color: #15325B;
    opacity: 0.65;
}

.card > .item > .login-label {
    font-family: PoppinsSemiBold;
    font-size: 1.2rem;
    color: #58585A;
}

.login-input {
    margin-top: 0.5rem;
    font-family: PoppinsRegular;
    font-size: 1rem;
    color: #58585A;
}
.logo {
    width: 200px;
    margin-top: 2%;
    margin-left: 2%;
}
.page {
    margin-left: 14%;
}
.header {
    margin-top: 1%;
}
.card {
    width: 81%;
    margin-top: 2%;
}
.toast-invisible {
    display: none;
}
.toast {
    width: 81%;
    align-items: center;
    display: flex;
    padding: 1rem;
    margin-top: 2%;
    font-family: RobotoRegular;
    font-size: 1rem;
    color: #58585A;
}
.toast-success {
    background: #34E40021;
}
.toast-error {
    background: #FF09098A;
}
.toast-partial {
    background: #F1EA2547;
}
.eye {
    margin: auto;
}
.underline {
    width: 9%;
    left: 2%;
    border-radius: 0.3rem;
    border-bottom: 0.5rem solid #1CBDBE;
}

@media (max-width: 750px) {
    .logo {
        width: 50%;
        margin-left: 18%;
    }
    .loginButton {
        width: 84%;
    }
    .underline {
        display: none;
    }
    .login-title {
        font-size: 1.5rem;
    }
    .page {
        margin-left: 10%;
    }
    .card {
        width: 85%;
    }
}
