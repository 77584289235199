.patient-content {
    height: 55%;
    overflow: auto;
    padding: 0 1rem;
}

.patient-item {
    margin: 0.5rem;
    border-radius: 0.5rem;
    --background: #F6F6F6;
    font-family: PoppinsRegular;
}

.patient-date-value {
    width: 80%;
}

.patient-title {
    font-family: RobotoBold;
    font-size: 1.5rem;
    color: #1CBDBE;
    margin: 1rem 0 0 1rem;
}

.patient-form {
    width: 60%;
}

.info-icon {
    cursor: pointer;
    font-size: 1.5rem; /* Adjust size as needed */
    color: #007bff; /* Change to your preferred color */
  }
  
  .tooltip {
    bottom: 125%; /* Position above the icon */
    background-color: #333; /* Tooltip background color */
    color: #fff; /* Tooltip text color */
    padding: 5px;
    border-radius: 5px;
    white-space: nowrap;
    z-index: 10;
    opacity: 0.8;
    font-size: 0.875rem; /* Tooltip font size */
    margin-bottom: 5px; /* Space between icon and tooltip */
  }

@media (max-width: 750px) {
    .patient-content {
        height: 67%;
        margin-top: 3%;
    }

    .patient-form {
        width: 100%;
    }
}