.clinician-content {
    height: 55%;
    overflow: auto;
    padding: 0 1rem;
}

.clinician-item {
    margin: 0.5rem;
    border-radius: 0.5rem;
    --background: #F6F6F6;
    font-family: PoppinsRegular;
}

.clinician-date-value {
    width: 80%;
}

.clinician-title {
    font-family: RobotoBold;
    font-size: 1.5rem;
    color: #1CBDBE;
    margin: 1rem 0 0 1rem;
}

.clinician-form {
    width: 60%;
}

@media (max-width: 750px) {
    .clinician-content {
        height: 67%;
        margin-top: 3%;
    }

    .clinician-form {
        width: 100%;
    }
}