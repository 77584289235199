.patientcard_container{
    width: 686px;
    height: 165px;
    border: 1px solid #999999;
    border-radius: 5px;
    cursor: pointer;
}
.patientcard_header{
    /* padding: 5px; */
    height:15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #999999;
}
.patient_subjectId{
    color: #535352;
    font-size: 14px;
    letter-spacing: -0.036em
}
.patientcard_details_container{
    height:85%;
    display: flex;
    justify-content: space-around;
  
}
.patientcard_detail_header{
    font-size: 12px;
    text-align: center;
    color: #535352;
    font-weight: 400;
}
.patientcard_day{
    font-size: 48px;
    color: #22BCBD;
    letter-spacing: 0.2em;
    text-align: center;
}
.patientcard_month{
    font-size: 24px;
    text-align: center;
    letter-spacing: 0.2em;
    color: #22BCBD;
}
.patientcard_notification_container{
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.patientcard_icon_container{
    display: flex;
    align-items: center;
}
.patientcard_notification_icon{
    width: 30px;
    padding-right: 15px;
}
.patientcard_sync_icon{
    font-size: 30px;
    color: #535352;
    padding-right: 15px;
}
.patientcard_indicator{
    width: 10px;
    height: 10px;
    background: #22BCBD;
}
.patientcard_button{
    background-color:#4d4d4d;
    width:108px;
    text-align:center;
    padding:3px 2px;
    color:#E6E6E6;
    font-size:14px;
    letter-spacing:0.2em;
    border-radius:5px;
}
@media screen and (max-height: 800px) {
    .patientcard_container{
       width: 500px;
    }
    .patientcard_day{
        font-size: 2.25rem;
    }
    .patientcard_detail_header{
        font-size: 0.7rem;
    }
    .patientcard_button{
        background-color:#4d4d4d;
        width:80px;
        text-align:center;
        font-size:0.8rem;
        padding: 3px;
        letter-spacing:0.2em;
        border-radius:5px;
    }
    .patientcard_notification_icon{
        width: 28px;
    }
    .patientcard_sync_icon{
        font-size: 28px;
    }
    
 }
