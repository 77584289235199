.messages_container{
    display: flex; 
    height: 95vh;
}
.messages_patient_list{
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    cursor: pointer;
}
.messages_box_container{
    width: 75%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between
}
.messages_file_placeholder{
    padding-bottom: 30px;
    width: 100px;
    cursor: pointer;
}
.clinician{
    width: fit-content;
    height: fit-content;
    padding: 10px; 
    border-radius: 5px;
    border: 1px solid #999999;
    color: #535352;
    background: #22BCBD;
    margin-bottom: 30px;
    
}

.ciontextarea{
    background: transparent;
    border: 1px solid #999999;    

   
}

.piontextarea{

    background:#22BCBD;
}

.patient{
    width: fit-content;
    height: fit-content;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #999999;
    color: #535352;
    margin-top: 30px;
    margin-right: 20px;
    float: right;
}

.pmsgts{
    width: fit-content;
    height: fit-content;
    font-size: 12px;
    padding: 0px;
    float: right;
}

.cmsgts{
    width: fit-content;
    height: fit-content;
    font-size: 12px;
    background: #22BCBD;

}

.selected_patient_text{
    color: #22BCBD;
    font-size: 14px;
    padding-left: 20px;
}

.patient_text{
    color: #535352;
    font-size: 14px;
    padding-left: 20px;
}
.selected_patient_icon{
    color: #22BCBD;
    font-size: 30px;
}
.patient_icon{
    color: #535352;
    font-size: 30px;
}

.messages_container_height{
  
    height: 100%;
    overflow-Y: scroll;
}
.messages_patient_container_height{
    width: 25%;
    height: 100%;
    overflow-Y: scroll;
}
.messages_selected_images{
    display:flex;
    align-items:center;
    justify-content:center;
    padding: 5px 10px;
    border: 1px solid #999999;
    width: fit-content;
    color: #535352;
    margin-right: 20px
}

.messages_cancel_button{
    padding-left:10px;
    position:relative;
    top:2px;
}



@media screen and ( max-height: 900px ) {
    /* .messages_patient_container_height{
        width: 25%;
        height: 600px;
        overflow-Y: scroll;
    } */
    .messages_container_height{
       
        height: 550px;
        overflow-Y: scroll;
    }
 }
